export interface InstanceType {
    id: string;
    label: string;
    details: {};
}

export interface TypeGroup {
    label: string;
    options: InstanceType[];
}

export interface InstanceTypes {
    type_groups: TypeGroup[];
}

export const instanceTypes: InstanceTypes = {
    type_groups: [
        {
            label: "General Purpose",
            options: [
                {
                    id: "ml.t3.medium",
                    label: "ml.t3.medium",
                    details: {GPU:0, CPU: 2, Memory : 4, GPUM:0},
                },
                {
                    id: "ml.t3.large",
                    label: "ml.t3.large",
                    details: {GPU:0, CPU: 2, Memory : 8, GPUM:0},
                },
                {
                    id: "ml.t3.xlarge",
                    label: "ml.t3.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.t3.2xlarge",
                    label: "ml.t3.2xlarge",
                    details: {GPU:0, CPU: 4, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.t2.medium",
                    label: "ml.t2.medium",
                    details: {GPU:0, CPU: 2, Memory : 4, GPUM:0},
                },
                {
                    id: "ml.t2.large",
                    label: "ml.t2.large",
                    details: {GPU:0, CPU: 2, Memory : 8, GPUM:0},
                },
                {
                    id: "ml.t2.xlarge",
                    label: "ml.t2.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.t2.2xlarge",
                    label: "ml.t2.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.m5.xlarge",
                    label: "ml.m5.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.m5.2xlarge",
                    label: "ml.m5.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.m5.4xlarge",
                    label: "ml.m5.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 64, GPUM:0},
                },
                {
                    id: "ml.m5.12xlarge",
                    label: "ml.m5.12xlarge",
                    details: {GPU:0, CPU: 48, Memory : 192, GPUM:0},
                },
                {
                    id: "ml.m5.24xlarge",
                    label: "ml.m5.24xlarge",
                    details: {GPU:0, CPU: 96, Memory : 384, GPUM:0},
                },
                {
                    id: "ml.m5d.large",
                    label: "ml.m5d.large",
                    details: {GPU:0, CPU: 2, Memory : 8, GPUM:0},
                },
                {
                    id: "ml.m5d.xlarge",
                    label: "ml.m5d.xlarge",
                    details: {GPU:0, CPU: 8, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.m5d.2xlarge",
                    label: "ml.m5d.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.m5d.4xlarge",
                    label: "ml.m5d.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 64, GPUM:0},
                },
                {
                    id: "ml.m5d.8xlarge",
                    label: "ml.m5d.8xlarge",
                    details: {GPU:0, CPU: 32, Memory : 128, GPUM:0},
                },
                {
                    id: "ml.m5d.12xlarge",
                    label: "ml.m5d.12xlarge",
                    details: {GPU:0, CPU: 48, Memory : 92, GPUM:0},
                },
                {
                    id: "ml.m5d.16xlarge",
                    label: "ml.m5d.16xlarge",
                    details: {GPU:0, CPU: 64, Memory : 256, GPUM:0},
                },
                {
                    id: "ml.m5d.24xlarge",
                    label: "ml.m5d.24xlarge",
                    details: {GPU:0, CPU: 96, Memory : 384, GPUM:0},
                },
                {
                    id: "ml.m4.xlarge",
                    label: "ml.m4.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.m4.2xlarge",
                    label: "ml.m4.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.m4.4xlarge",
                    label: "ml.m4.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 64, GPUM:0},
                },
                {
                    id: "ml.m4.10xlarge",
                    label: "ml.m4.10xlarge",
                    details: {GPU:0, CPU: 40, Memory : 160, GPUM:0},
                },
                {
                    id: "ml.m4.16xlarge",
                    label: "ml.m4.16xlarge",
                    details: {GPU:0, CPU: 64, Memory : 256, GPUM:0},
                }
            ]
        },
        {
            label: "Compute Optimized",
            options: [
                {
                    id: "ml.c5.xlarge",
                    label: "ml.c5.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 8, GPUM:0},
                },
                {
                    id: "ml.c5.2xlarge",
                    label: "ml.c5.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.c5.4xlarge",
                    label: "ml.c5.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.c5.9xlarge",
                    label: "ml.c5.9xlarge",
                    details: {GPU:0, CPU: 36, Memory : 72, GPUM:0},
                },
                {
                    id: "ml.c5.18xlarge",
                    label: "ml.c5.18xlarge",
                    details: {GPU:0, CPU: 72, Memory : 144, GPUM:0},
                },
                {
                    id: "ml.c5d.xlarge",
                    label: "ml.c5d.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 8, GPUM:0},
                },
                {
                    id: "ml.c5d.2xlarge",
                    label: "ml.c5d.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.c5d.4xlarge",
                    label: "ml.c5d.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.c5d.9xlarge",
                    label: "ml.c5d.9xlarge",
                    details: {GPU:0, CPU: 36, Memory : 72, GPUM:0},
                },
                {
                    id: "ml.c5d.18xlarge",
                    label: "ml.c5d.18xlarge",
                    details: {GPU:0, CPU: 72, Memory : 144, GPUM:0},
                },
                {
                    id: "ml.c4.xlarge",
                    label: "ml.c4.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 7.5, GPUM:0},
                },
                {
                    id: "ml.c4.2xlarge",
                    label: "ml.c4.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 15, GPUM:0},
                },
                {
                    id: "ml.c4.4xlarge",
                    label: "ml.c4.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 30, GPUM:0},
                },
                {
                    id: "ml.c4.8xlarge",
                    label: "ml.c4.8xlarge",
                    details: {GPU:0, CPU: 36, Memory : 60, GPUM:0},
                }
            ]
        },
        {
            label: "Memory Optimized",
            options: [
                {
                    id: "ml.r5.large",
                    label: "ml.r5.large",
                    details: {GPU:0, CPU: 2, Memory : 16, GPUM:0},
                },
                {
                    id: "ml.r5.xlarge",
                    label: "ml.r5.xlarge",
                    details: {GPU:0, CPU: 4, Memory : 32, GPUM:0},
                },
                {
                    id: "ml.r5.2xlarge",
                    label: "ml.r5.2xlarge",
                    details: {GPU:0, CPU: 8, Memory : 64, GPUM:0},
                },
                {
                    id: "ml.r5.4xlarge",
                    label: "ml.r5.4xlarge",
                    details: {GPU:0, CPU: 16, Memory : 128, GPUM:0},
                },
                {
                    id: "ml.r5.8xlarge",
                    label: "ml.r5.8xlarge",
                    details: {GPU:0, CPU: 32, Memory : 256, GPUM:0},
                },
                {
                    id: "ml.r5.12xlarge",
                    label: "ml.r5.12xlarge",
                    details: {GPU:0, CPU: 48, Memory : 384, GPUM:0},
                },
                {
                    id: "ml.r5.16xlarge",
                    label: "ml.r5.16xlarge",
                    details: {GPU:0, CPU: 64, Memory : 512, GPUM:0},
                },
                {
                    id: "ml.r5.24xlarge",
                    label: "ml.r5.24xlarge",
                    details: {GPU:0, CPU: 96, Memory : 768, GPUM:0},
                }
            ]
        },
        {
            label: "Accelerated Computing",
            options: [
                {
                    id: "ml.p3.2xlarge",
                    label: "ml.p3.2xlarge",
                    details: {GPU: 1 , CPU: 8, Memory : 61, GPUM: 16},
                },
                {
                    id: "ml.p3.8xlarge",
                    label: "ml.p3.8xlarge",
                    details: {GPU: 4, CPU: 32, Memory : 244, GPUM: 64},
                },
                {
                    id: "ml.p3.16xlarge",
                    label: "ml.p3.16xlarge",
                    details: {GPU: 8, CPU: 64, Memory : 488, GPUM: 128},
                },
                {
                    id: "ml.p4d.24xlarge",
                    label: "ml.p4d.24xlarge",
                    details: {GPU: 8, CPU: 96, Memory : 1152, GPUM: 320},
                },
                {
                    id: "ml.p4de.24xlarge",
                    label: "ml.p4de.24xlarge",
                    details: {GPU: 8, CPU: 96, Memory : 1152, GPUM: 640},
                },
                {
                    id: "ml.g4dn.xlarge",
                    label: "ml.g4dn.xlarge",
                    details: {GPU: 1, CPU: 4, Memory : 16, GPUM: 16},
                },
                {
                    id: "ml.g4dn.2xlarge",
                    label: "ml.g4dn.2xlarge",
                    details: {GPU: 1, CPU: 8, Memory : 32, GPUM: 16},
                },
                {
                    id: "ml.g4dn.4xlarge",
                    label: "ml.g4dn.4xlarge",
                    details: {GPU: 1, CPU: 16, Memory : 64, GPUM: 16},
                },
                {
                    id: "ml.g4dn.8xlarge",
                    label: "ml.g4dn.8xlarge",
                    details: {GPU: 1, CPU: 32, Memory : 128, GPUM: 16},
                },
                {
                    id: "ml.g4dn.12xlarge",
                    label: "ml.g4dn.12xlarge",
                    details: {GPU: 4, CPU: 48, Memory : 192, GPUM: 64},
                },
                {
                    id: "ml.g4dn.16xlarge",
                    label: "ml.g4dn.16xlarge",
                    details: {GPU: 1, CPU: 64, Memory : 256, GPUM: 16},
                },
                {
                    id: "ml.g5.xlarge",
                    label: "ml.g5.xlarge",
                    details: {GPU: 1, CPU: 4, Memory : 16, GPUM: 24},
                },
                {
                    id: "ml.g5.2xlarge",
                    label: "ml.g5.2xlarge",
                    details: {GPU: 1, CPU: 8, Memory : 32, GPUM: 24},
                },
                {
                    id: "ml.g5.4xlarge",
                    label: "ml.g5.4xlarge",
                    details: {GPU: 1, CPU: 16, Memory : 64, GPUM: 24},
                },
                {
                    id: "ml.g5.8xlarge",
                    label: "ml.g5.8xlarge",
                    details: {GPU: 1, CPU: 32, Memory : 128, GPUM: 24},
                },
                {
                    id: "ml.g5.12xlarge",
                    label: "ml.g5.12xlarge",
                    details: {GPU: 4, CPU: 48, Memory : 192, GPUM: 96},
                },
                {
                    id: "ml.g5.16xlarge",
                    label: "ml.g5.16xlarge",
                    details: {GPU: 1, CPU: 64, Memory : 256, GPUM: 24},
                },
                {
                    id: "ml.g5.24xlarge",
                    label: "ml.g5.24xlarge",
                    details: {GPU: 4, CPU: 96, Memory : 384, GPUM: 96},
                },
                {
                    id: "ml.g5.48xlarge",
                    label: "ml.g5.48xlarge",
                    details: {GPU: 8, CPU: 192, Memory : 768, GPUM: 192},
                }
            ]
        }
    ]
};

function displayMessage(details: { [x: string]: any; }){
    let gpu = details['GPU'];
    let cpu = details['CPU'];
    let mem = details['Memory'];
    let gpum = details['GPUM'];
    if(gpu === 0 && gpum === 0)
        return `${cpu} vCPUs and ${mem} GiB of Memory.`
    else if(gpu > 0 && gpum > 0)
        return `${cpu} vCPUs, ${mem} GiB of Memory, ${gpu} GPUs and ${gpum} GiB of GPU Memory.`
    else{
        if(gpu > 0)
            return `${cpu} vCPUs, ${mem} GiB of Memory and ${gpu} GPUs.`
        else
            return `${cpu} vCPUs, ${mem} GiB of Memory and ${gpum} GiB of GPU Memory.`
    }
}

export function instance_additional_gpu_info(instance_type: string){
    for ( const idxA in instanceTypes['type_groups']) {
        for ( const idxB in instanceTypes['type_groups'][idxA]['options']){
            if(instanceTypes['type_groups'][idxA]['options'][idxB]['id'] === instance_type){
                let details = instanceTypes['type_groups'][idxA]['options'][idxB]['details']
                let message = displayMessage(details);
                return message;
            }
        }
    }
}